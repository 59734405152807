import React from 'react';
import img from '../images/philly-fountain.jpg'; 


function FountainImage() {
  return <img src={img} className="fountain-image" alt="Fountain" />;
}

export default FountainImage;

